import { baseUrl } from "./config";

const GET = "GET";
const POST = "POST";
const PUT = "PUT"
//this page handle the communication with the backend (get, put service)

//authorize access to the backend and api
const withAuthorization = (token: string, headers: Headers | undefined = new Headers()) => {
    headers.append("Authorization", "Bearer " + token);
    return headers;
};

const withContentType = (headers: Headers | undefined = new Headers()) => {
    headers.append("Content-Type", "application/json")
    return headers
}

export const loginService = async <T = any>(url: string, programId: number, code: string, userAgent: string): Promise<T> => {
    const requestUrl = baseUrl + url;
    const payload = { programId, code, userAgent };
    const response = await fetch(encodeURI(requestUrl), {
        method: POST,
        headers: withContentType(),
        body: JSON.stringify(payload)
    });

    return handleResponse(response)
}

function timeout(delay: number) {
  return new Promise(function(resolve, reject) {
      setTimeout(function() {
          resolve(new Response('', {
              status: 408,
              statusText: 'Request timed out.'
          }));
      }, delay);
  });
}

export const getService = async <T>(
    token: string | null,
    url: string,
    queryParam: string | undefined = ""
): Promise<T> => {
    const requestUrl = baseUrl + "/" + url + queryParam;
    let headers = (token ? withAuthorization(token) : undefined);
    const response = await Promise.race([timeout(10000), fetch(encodeURI(requestUrl), {
        method: GET,
        headers: headers,
    })]);

    return handleResponse(response as Response)
}

export const putService = async <T>(
    token: string,
    url: string,
    body: object,
    queryParam: string | undefined = ""
  ): Promise<T> => {
    const requestUrl = baseUrl + "/" + url + queryParam;
    const headers = withContentType(withAuthorization(token));

    const response = await fetch(encodeURI(requestUrl), {
      method: PUT,
      headers: headers,
      mode: "cors",
      body: JSON.stringify(body)
    });

    return handleResponse(response)
  };

export const postService = async <T>(
    token: string,
    url: string,
    body: object,
    queryParam: string | undefined = ""
  ): Promise<T> => {
    const requestUrl = baseUrl + "/" + url + queryParam;
    const headers = withContentType(withAuthorization(token));

    const response = await fetch(encodeURI(requestUrl), {
      method: POST,
      headers: headers,
      mode: "cors",
      body: JSON.stringify(body)
    });

    return handleResponse(response)
  };

const handleResponse = async <T>(response: Response): Promise<T> => {
    const text = await response.text()
    const payload = text ? JSON.parse(text) : response
    return isSuccess(response)
        ? Promise.resolve(payload)
        : Promise.reject({ httpStatus: response.status, payload })
}

/**
 * Returns true if response status is 2xx
 * @param response
 */
const isSuccess = (response: Response): boolean => {
    const status = response.status
    return status >= 200 && status < 300
}
