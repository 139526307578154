import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getService} from 'api'
import {handleError} from '../../utils/httpErrorCodes'

import {AppState} from 'redux/rootReducer'
import {
  AllowedChapters,
  chatGroupDetails,
  CourseDefinition,
  Details,
  ErrorType,
  InstructorDetails,
  Media,
  MyCourses,
  Section
} from 'types'

interface MyCoursesParams {
  token: string
}

export const fetchMyCourses: AsyncThunk<MyCourses,
  MyCoursesParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myCourses/fetchMyCourses',
  async ({token}: MyCoursesParams, {rejectWithValue}) => {
    try {
      const url = `training/my-courses`
      return await getService(token, url)
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface FetchInstructorDetailsParams {
  userId: string,
  courseSlug: string,
  token: string
}

export interface InstructorDetailsThunkState extends InstructorDetails {
  courseSlug: string
}

export const fetchInstructorDetails: AsyncThunk<InstructorDetailsThunkState,
  FetchInstructorDetailsParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myCourses/getInstructorDetails',
  async ({userId, courseSlug, token}: FetchInstructorDetailsParams, {rejectWithValue}) => {
    try {
      const url = `training/my-courses/${courseSlug}/instructor`
      const fetchedInstructorDetails: InstructorDetails = await getService(token, url)
      return {...fetchedInstructorDetails, courseSlug}
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)


interface FetchChatGroupDetailsParams {
  userId: string,
  courseSlug: string,
  token: string
}

export interface ChatGroupDetailsThunkState extends chatGroupDetails {
  courseSlug: string
}

export const fetchChatGroupDetails: AsyncThunk<ChatGroupDetailsThunkState,
  FetchChatGroupDetailsParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myCourses/getChatGroupDetails',
  async ({userId, courseSlug, token}: FetchInstructorDetailsParams, {rejectWithValue}) => {
    try {
      const url = `training/my-courses/${courseSlug}/chat-group`
      const fetchedChatGroupDetails: chatGroupDetails = await getService(token, url)
      return {...fetchedChatGroupDetails, courseSlug}
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface FetchCourseBySlugParams {
  courseSlug: string
  programId: number
  token: string
}

export const fetchCourseBySlug: AsyncThunk<CourseDefinition,
  FetchCourseBySlugParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myCourses/fetchCourseBySlug',
  async ({courseSlug, programId, token}: FetchCourseBySlugParams, {rejectWithValue}) => {
    try {
      const url = `library/${programId}/courses/${courseSlug}`
      return await getService(token, url)
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface AllowedChaptersParams {
  courseSlug: string
  userId: string,
  token: string
}

export interface AllowedChaptersThunkState {
  courseSlug: string
  allowedChapters: AllowedChapters
}

export const fetchAllowedChapters: AsyncThunk<AllowedChaptersThunkState,
  AllowedChaptersParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myCourses/fetchAllowedChapters',
  async ({courseSlug, token, userId}: AllowedChaptersParams, {rejectWithValue}) => {
    try {
      const url = `training/my-courses/${courseSlug}/allowed-chapters`
      const allowedChapters: AllowedChapters = await getService(token, url)
      return {
        courseSlug,
        allowedChapters
      }
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export interface ChapterThunkState {
  id: string
  courseSlug: string
  sections: Array<Section>
  allMediaDownloaded: boolean
  downloadFinished: boolean
}

export interface ChapterParams {
  id: string
  courseSlug: string
  programId: number
  token: string
}

export interface MediaResult {
  status: number
  blob: Blob
}

const getMedia = async (url: string): Promise<MediaResult | undefined> => {
  try {
    const res = await fetch(url, { mode: 'cors' })
    const blob = await res.blob()
    return {
      status: res.status,
      blob
    }
  } catch (err) {
    console.log(err)
  }
  
}

export const fetchChapterMediaListById = async (id: string, courseSlug: string, programId: number, token: string) => {
  try {
    const screenWidth = document.documentElement.clientWidth
    const density = window.devicePixelRatio
    const mediaUrl = `library/${programId}/media/${id}/${screenWidth}/${density}`
    const fetchedMedias: Array<Media> = await getService(token, mediaUrl)
    
    const downloadedMedias = fetchedMedias.map(media => {
      return getMedia(media.mediaUrl).then(() => {
      })
    })
    
    return {
      id,
      courseSlug,
      mediaList: downloadedMedias
    }
  } catch (err) {
    return handleError(err)
  }
}


export const fetchChapterById: AsyncThunk<ChapterThunkState,
  ChapterParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myCourses/fetchChapterById',
  async ({id, courseSlug, programId, token}: ChapterParams, {rejectWithValue}) => {
    try {
      const sectionsUrl = `library/${programId}/courses/chapters/${id}`
      const fetchedSections: Array<Section> = await getService(token, sectionsUrl)
      
      return {
        id,
        courseSlug,
        sections: fetchedSections,
        downloadFinished: (false) as boolean,
        allMediaDownloaded: (false) as boolean
      }
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)


export interface ChapterState extends Details {
  currentSection?: string
  visitedSections?: Array<string>
  completed?: boolean
  allMediaDownloaded: boolean
  downloadFinished: boolean
  successSnackbarShown: boolean
  sections: {
    [id: string]: Section
  }
}

interface CourseState extends Details {
  summary: string
  accessed: boolean
  shownCompletionDialog?: boolean
  chapters?: {
    [id: string]: ChapterState
  }
  allowedChapters: AllowedChapters
  instructor: InstructorDetails
  chatGroup: chatGroupDetails
}

interface MyCoursesState {
  bySlug: {
    [slug: string]: CourseState
  },
  isLoading: boolean
  isFetchingAllowedChapters: boolean
  error: ErrorType | null
}

const initialState: MyCoursesState = {
  bySlug: {},
  isLoading: false,
  isFetchingAllowedChapters: false,
  error: null
}

interface CurrentSection {
  id: string
  courseSlug: string
  chapterId: string
}

interface SuccessSnackbarShownPayload {
  courseSlug: string
  chapterId: string
  successSnackbarShown: boolean
}


interface MediaDownloadCompletePayload {
  courseSlug: string
  chapterId: string
  mediaDownloadComplete: boolean
  allMediaDownloaded: boolean
}


const myCoursesSlice = createSlice({
  name: 'myCourses',
  initialState,
  reducers: {
    setAccessed(state, {payload}: PayloadAction<string>) {
      state.bySlug[payload].accessed = true
    },
    setCurrentSection(state, {payload}: PayloadAction<CurrentSection>) {
      const {id, courseSlug, chapterId} = payload
      const getVisitedSections = () => {
        const chapterToUpdate = state.bySlug[courseSlug].chapters![chapterId]
        const visitedSections = chapterToUpdate.visitedSections || []
        if (id) {
          const hasId = visitedSections.includes(id.toString())
          return hasId
            ? visitedSections
            : visitedSections.concat(id.toString())
        }
        
        return visitedSections
      }
      
      state.bySlug[courseSlug].chapters![chapterId] = {
        ...state.bySlug[courseSlug].chapters![chapterId],
        currentSection: id,
        visitedSections: getVisitedSections()
      }
    },
    setChapterCompleted(state, {payload}: PayloadAction<Omit<CurrentSection, "id">>) {
      const {courseSlug, chapterId} = payload
      state.bySlug[courseSlug].chapters![chapterId] = {
        ...state.bySlug[courseSlug].chapters![chapterId],
        completed: true
      }
    },
    setCourseCompletion(state, {payload}: PayloadAction<string>) {
      state.bySlug[payload].shownCompletionDialog = true
    },
    setSuccessSnackbarShown(state, {payload}: PayloadAction<SuccessSnackbarShownPayload>) {
      const {chapterId, courseSlug, successSnackbarShown} = payload
      state.bySlug[courseSlug].chapters![chapterId].successSnackbarShown = successSnackbarShown
    },
    setMediaDownloadComplete(state, {payload}: PayloadAction<MediaDownloadCompletePayload>) {
      const {chapterId, courseSlug, mediaDownloadComplete, allMediaDownloaded} = payload
      state.bySlug[courseSlug].chapters![chapterId].downloadFinished = mediaDownloadComplete
      state.bySlug[courseSlug].chapters![chapterId].allMediaDownloaded = allMediaDownloaded
    }
  },
  extraReducers: {
    [fetchMyCourses.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchMyCourses.fulfilled.type]: (state, action: PayloadAction<MyCourses>) => {
      state.bySlug = action.payload.reduce((acc, course) => {
        return {
          ...acc,
          [course.slug]: {
            ...state.bySlug[course.slug],
            ...course
          }
        }
      }, {})
      state.isLoading = false
      state.error = null
    },
    [fetchMyCourses.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    },
    [fetchCourseBySlug.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchCourseBySlug.fulfilled.type]: (state, {payload}: PayloadAction<CourseDefinition>) => {
      state.isLoading = false
      const chapters = Array.isArray(payload.chapters) ? payload.chapters : []
      const hasChapters = state.bySlug[payload.slug].hasOwnProperty('chapters')
      state.bySlug[payload.slug] = {
        ...state.bySlug[payload.slug],
        chapters: chapters.reduce((acc, chapter) => {
          if (hasChapters) {
            const currentChapter = state.bySlug[payload.slug].chapters![chapter.id]
            return currentChapter !== undefined
              ? {
                ...acc,
                [chapter.id]: currentChapter
              }
              : {
                ...acc,
                [chapter.id]: chapter
              }
          }
          
          return {
            ...acc,
            [chapter.id]: chapter
          }
        }, {})
      }
      state.error = null
    },
    [fetchCourseBySlug.rejected.type]: (state, action: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchInstructorDetails.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchInstructorDetails.fulfilled.type]: (state, {payload}: PayloadAction<InstructorDetailsThunkState>) => {
      const {courseSlug, name, photo} = payload
      state.bySlug[courseSlug].instructor = {name, photo}
      state.isLoading = false
      state.error = null
    },
    [fetchInstructorDetails.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = null // will not catch instructor 404 errors
    },
    [fetchChatGroupDetails.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchChatGroupDetails.fulfilled.type]: (state, {payload}: PayloadAction<ChatGroupDetailsThunkState>) => {
      const {courseSlug, url, provider} = payload
      state.bySlug[courseSlug].chatGroup = {url, provider}
      state.isLoading = false
      state.error = null
    },
    [fetchChatGroupDetails.rejected.type]: (state, action: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchAllowedChapters.pending.type]: (state, _) => {
      state.isFetchingAllowedChapters = true
    },
    [fetchAllowedChapters.fulfilled.type]: (state, {payload}: PayloadAction<AllowedChaptersThunkState>) => {
      const {allowedChapters, courseSlug} = payload
      state.isFetchingAllowedChapters = false
      const isArray = Array.isArray(allowedChapters)
      state.bySlug[courseSlug].allowedChapters = isArray ? allowedChapters : []
      state.error = null
    },
    [fetchAllowedChapters.rejected.type]: (state, action: PayloadAction<ErrorType>) => {
      state.isFetchingAllowedChapters = false
      state.error = action.payload
    },
    [fetchChapterById.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchChapterById.fulfilled.type]: (state, {payload}: PayloadAction<ChapterThunkState>) => {
      const {courseSlug, id, sections, allMediaDownloaded} = payload
      state.isLoading = false
      state.error = null
      state.bySlug[courseSlug].chapters![id] = {
        ...state.bySlug[courseSlug].chapters![id],
        allMediaDownloaded,
        sections: sections.reduce((acc, section) => {
          return {
            ...acc,
            [section.id]: section
          }
        }, {})
      }
    },
    [fetchChapterById.rejected.type]: (state, action: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const {
  setAccessed, setCurrentSection,
  setChapterCompleted, setCourseCompletion,
  setSuccessSnackbarShown, setMediaDownloadComplete
} = myCoursesSlice.actions

export default myCoursesSlice.reducer

const selectCourseSlugs = (state: AppState) => {
  return Object.keys(state.myCourses.bySlug)
}

export const selectCourseBySlug = (state: AppState, courseSlug: string): CourseState => {
  return state.myCourses.bySlug[courseSlug]
}

export const selectInstructorBySlug = (state: AppState, courseSlug: string): InstructorDetails => {
  return selectCourseBySlug(state, courseSlug).instructor
}

export const selectChatGroupBySlug = (state: AppState, courseSlug: string): chatGroupDetails => {
  return selectCourseBySlug(state, courseSlug).chatGroup
}

export const isDefined = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
}

export const selectMyCourses = (state: AppState): Array<CourseState> => {
  return selectCourseSlugs(state)
    .map(courseSlug => selectCourseBySlug(state, courseSlug))
    .filter(isDefined)
}

export const selectIsLoading = (state: AppState): boolean => {
  return state.myCourses.isLoading
}

export const selectIsFetchingAllowedChapters = (state: AppState): boolean => {
  return state.myCourses.isFetchingAllowedChapters
}

export const selectError = (state: AppState): ErrorType | null => {
  const {error} = state.myCourses
  return error
}

export const selectChapterById = (state: AppState, courseSlug: string, chapterId: string): ChapterState => {
  return selectCourseBySlug(state, courseSlug).chapters![chapterId]
}

export const selectSections = (state: AppState, courseSlug: string, chapterId: string) => {
  const sections = selectChapterById(state, courseSlug, chapterId).sections
  return sections
    ? Object.keys(sections).map(key => sections[key]).sort((a, b) => a.order - b.order)
    : []
}

export const selectCurrentSectionId = (
  state: AppState,
  courseSlug: string,
  chapterId: string): string | undefined => {
  return selectChapterById(state, courseSlug, chapterId).currentSection
}

export const selectSection = (state: AppState,
                              courseSlug: string,
                              chapterId: string): Section | undefined => {
  const currentSectionId = selectCurrentSectionId(state, courseSlug, chapterId)
  const sections = selectSections(state, courseSlug, chapterId)
  if (currentSectionId) {
    return sections.find(section => section.id.toString() === currentSectionId.toString())!
  }
  return;
}

export const selectSectionIds = (state: AppState, courseSlug: string, chapterId: string) => {
  const sections = selectSections(state, courseSlug, chapterId)
  return sections.map(section => section.id)
}
