import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getService} from 'api';
import {baseUrl} from 'api/config';
import {handleError} from '../../utils/httpErrorCodes';
import {AppState} from 'redux/rootReducer';
import {Collect, ErrorType, Survey, SurveyContent, Value} from 'types';

interface FetchTransactionSurveyParams {
  token: string,
  programId:number
}

export const fetchTransactionSurveyByCollect: AsyncThunk<Survey,
  FetchTransactionSurveyParams,
  { rejectValue: ErrorType }> = createAsyncThunk(
  'transactions/fetchTransactionSurveyByCollect',
  async ({token, programId}: FetchTransactionSurveyParams, {rejectWithValue}) => {
    try {
      const url = `collect/getSurveyByCollect?collect=${programId}9999`
      const fetchedTransactionSurvey: Survey = await getService(token, url)
      return fetchedTransactionSurvey
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface NetworkError {
  name: string,
  response: {
    message: string
  },
  status: number
}

export interface AnswerValue {
  questionId: string
  value: Value
  inputDate: string
  order?: number // used for transaction hack only
}

export interface SurveyState extends Collect {
  answeredQuestionIds: Array<string>
  currentQuestionId?: string
  answerValues: Array<AnswerValue>
  submitted: boolean
  submissionDate: string
  pending: boolean
}

interface TransactionsState {
  survey: Survey | undefined
  loading: boolean
  error: ErrorType | null
  showSuccessInfo: boolean
  answeredQuestionIds: Array<string>
  currentQuestionId?: string
  answerValues: Array<AnswerValue>
  submitted: boolean
  submissionDate?: string
  pending: boolean
}

const initialState: TransactionsState = {
  survey: undefined,
  loading: false,
  error: null,
  showSuccessInfo: false,
  answeredQuestionIds: [],
  currentQuestionId: undefined,
  answerValues: [],
  submitted: false,
  submissionDate: undefined,
  pending: false
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setCurrentQuestion(state, {payload}: PayloadAction<string | undefined>) {
      state.currentQuestionId = payload
    },
    addAnsweredQuestionId(state, {payload}: PayloadAction<string>) {
      const hasId = state.answeredQuestionIds.includes(payload.toString())
      if (!hasId) {
        state.answeredQuestionIds = state.answeredQuestionIds
          .concat(payload.toString())
      }
    },
    removeAnsweredQuestionId(state, {payload}: PayloadAction<string>) {
      state.answeredQuestionIds = state
        .answeredQuestionIds
        .filter(id => id !== payload)
    },
    setAnswerValues(state, {payload}: PayloadAction<Array<AnswerValue>>) {
      state.answerValues = payload
    },
    sendTransactionResults: {
      reducer(state, {payload}: PayloadAction<{}>) {
        state.pending = true
      },
      prepare(token: string, surveyResults: SurveyContent) {
        return {
          payload: surveyResults.surveyCmsId,
          meta: {
            offline: {
              // the network action to execute:
              effect: {
                url: `${baseUrl}/collect/postSurveyResults`,
                method: 'POST',
                body: JSON.stringify(surveyResults),
                headers: {
                  "Authorization": `Bearer ${token}`
                }
              },
              // action to dispatch when effect succeeds:
              commit: {
                type: 'transactions/addTransactionResults',
              },
              // action to dispatch if network action fails permanently:
              rollback: {
                type: 'transactions/rollbackResults'
              },
            }
          }
        }
      }
    },
    addTransactionResults(state, _) {
      state.loading = false
      state.pending = false
      state.submitted = true
      state.submissionDate = new Date().toLocaleString()
      state.error = null
      state.showSuccessInfo = true
    },
    rollbackResults(state, {payload}: PayloadAction<NetworkError>) {
      const message = payload.status >= 500
        ? "Error: Failed to Send Survey!"
        : payload.response.message
      state.loading = false
      state.error = {
        httpStatus: payload.status,
        payload: {
          message
        }
      }
    },
    setShowSuccessInfo(state, {payload}: PayloadAction<boolean>) {
      state.showSuccessInfo = payload
    }
  },
  extraReducers: {
    [fetchTransactionSurveyByCollect.pending.type]: (state, _) => {
      state.loading = true
    },
    [fetchTransactionSurveyByCollect.fulfilled.type]: (state, {payload}: PayloadAction<Survey>) => {
      state.survey = payload
      state.loading = false
      state.error = null
    },
    [fetchTransactionSurveyByCollect.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.loading = false
      state.error = payload
    }
  }
})

export const {
  setCurrentQuestion, addAnsweredQuestionId,
  removeAnsweredQuestionId, setAnswerValues, sendTransactionResults,
  addTransactionResults, rollbackResults, setShowSuccessInfo
} = transactionsSlice.actions

export default transactionsSlice.reducer

export const selectTransactionSurveyByCollect =
  (state: AppState): TransactionsState => {
    return state.transactions
  }

export const selectTransaction = (state: AppState) => state.transactions.survey

export const selectShowSuccessInfo = (state: AppState) => state.transactions.showSuccessInfo

export const selectLoading = (state: AppState): boolean => state.transactions.loading

export const selectError = (state: AppState): ErrorType | null => state.transactions.error